import React,{useEffect} from 'react';
import { animated } from '@react-spring/web'; 
import success from '../assets/success.png';
import { MDBBtn } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';

export default function Success() {
  useEffect(() => {
    document.body.style.backgroundColor = "#07060d";
  }, []);
  return (
    <div style={{ marginTop: "10%", textAlign: "center" }}>
      <center>
        <animated.img
          src={success}
          style={{
            width: "200px",
            animation: "bounce 1.5s infinite"
          }}
          alt="Success"
        />
      </center>
      <animated.h3
        style={{
          color: "white",
          marginTop: "10px",
          fontSize: "40px",
          animation: "fadeIn 2s ease-in-out"
        }}
      >
        Thank You!
      </animated.h3>
      <p
        style={{
          color: "#c0c7d3",
        }}
      >
        We have received your submission
      </p>
      <Link to="/">
        <MDBBtn
          style={{
            borderRadius: "50px",
            marginTop: "1%",
            backgroundColor: "#4100a3",
          }}
        >
          Return
        </MDBBtn>
      </Link>
    </div>
  );
}
