import React, { useEffect, useState } from 'react';
import logo from "../assets/logo.png";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useNavigate } from 'react-router-dom';

export default function Main() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.backgroundColor = "#07060d";
  }, []);

  function onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    fetch("https://formcarry.com/s/ApLpTcQjwmi", {
      method: 'POST',
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ name, email, message })
    })
      .then(response => response.json())
      .then(response => {
        if (response.code === 200) {
          navigate('/success')
        } else if (response.code === 422) {
          console.log("error");
        } else {
          console.log("error");
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  return (
    <div style={{ marginTop: "4%", textAlign: "center" }}>
      <center>
        <img src={logo} style={{ textAlign: "center", justifyContent: "center", width: "130px" }} alt="Logo" />
      </center>
      <h3 className="gradient-text">Join the waitlist for the <span className='gradient-text1'>RYZENT</span></h3>

      <section className="formcarry-container" style={{marginTop:"-5px"}}>
        <form onSubmit={onSubmit}>
          <input type="hidden" name="_next" value="https://yourthank.you/page" />
          
          <div className="formcarry-block">
            <label htmlFor="fc-generated-1-name" style={{textAlign:"left"}}>Full Name</label>
            <input 
              type="text" 
              name="name" 
              id="fc-generated-1-name" 
              placeholder="Your first and last name" 
              value={name} 
              onChange={(e) => setName(e.target.value)} 
            />
          </div>
          
          <div className="formcarry-block">
            <label htmlFor="fc-generated-1-email" style={{textAlign:"left"}}>Your Email Address</label>
            <input 
              type="email" 
              name="email" 
              id="fc-generated-1-email" 
              placeholder="john@doe.com" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
            />
          </div>
          
          <div className="formcarry-block">
            <label htmlFor="fc-generated-1-message" style={{textAlign:"left"}}>Your message</label>
            <textarea 
              name="message" 
              id="fc-generated-1-message" 
              placeholder="Enter your message..." 
              value={message} 
              onChange={(e) => setMessage(e.target.value)} 
            />
          </div>
          
          <div className="formcarry-block">  
            <button type="submit" style={{backgroundColor:"white",color:"black"}}>Send</button>
          </div>
        </form>
      </section>
    </div>
  );
}
